import React from 'react'
import { Form } from 'react-bootstrap'
import { InboxOutlined } from '@ant-design/icons'
import { Upload } from "antd"

import { storeAttachment } from '../services'

const { Dragger } = Upload

export default function NewAttachmentForm({ 
    deliveryId, onSuccess = () => {}, defaultLabel = ''
}) {
    const props = {
        multiple: false,
        customRequest: async ({ file, onError, onSuccess: handleSuccess }) => {
            const fileExt = file.name.split('.').pop().toLowerCase()
            const values = new FormData()
            values.append('file', file)
            values.append('file_path', `asisfarma/deliveries/${deliveryId}/${new Date().getTime()}.${fileExt}`)
            values.append('delivery_id', deliveryId)
            values.append('label', defaultLabel)

            try {
                const response = await storeAttachment(values)
                handleSuccess(response.data, file)
                onSuccess(response.data.url)
            } catch (error) {
                onError(error)
                console.error('Error al subir el archivo: ', error.message)
            }
        }
    };

    return (
        <React.Fragment>
            <Form.Group className="mb-10">
				<div>
					<Form.Label>Subir Fórmula Médica y MIPRES <small>(sí aplica)</small></Form.Label>
				</div>
				<Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Haga clic o arrastre el adjunto
                    </p>
                    <p className="ant-upload-hint">
                        Solo se permite adjuntar un archivo menor a 5MB.
                    </p>
                </Dragger>
			</Form.Group>
        </React.Fragment>
    )
}
